@import './shared/styles/_vuetify-overrides.scss';






























.health-status-indicator {
	width: 20px;
	height: 20px;
	border-radius: 50px;
}
